import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'

class NotFoundPage extends React.Component {  
  render() {    
    return (
      <Layout>
        <Helmet>
          <meta name="ROBOTS" content="NOINDEX" />
        </Helmet>
        <div className="col-md-6 offset-md-3 text-center mt-5">
          <h1 className="display-1">404</h1>
          <p className="mt-3 h2">
            Oops! Can't seem to find the page you are looking for
          </p>
          <p className="call-button mt-3 mb-5">
            <Link to="/" className="hvr-sweep-to-top  wow flipInX">Click here to know more about Therapetic</Link>
          </p>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage